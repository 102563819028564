import * as React from "react";
import { Helmet } from "react-helmet";

const Index = ({ pageContext, location }) => {
  const {
    pagePath,
    title,
    buildDate,
    yearInfo,
    metaTitle,
    firstYear,
    lastYear,
  } = pageContext;

  const metaDescription = `History of ${title} between years ${firstYear} and ${lastYear}.`;

  const sb = Object.keys(yearInfo).map((year) => {
    const paragraphs = yearInfo[year].map((sentence) => {
      return <p>{sentence}</p>;
    });

    return (
      <div>
        <h3>{year}</h3>
        {paragraphs}
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#efe4e8",
        margin: "-10px",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <link rel="canonical" href={"https://yearis.com/" + pagePath} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div style={{ padding: "48px", maxWidth: "80vw", background: "white" }}>
        <h1>{(title || "").replace(/_/g, " ")}</h1>
        {sb}
        <br />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            All text is taken from Wikipedia. Text is available under the{" "}
            <a
              rel="license"
              href="//en.wikipedia.org/wiki/Wikipedia:Text_of_Creative_Commons_Attribution-ShareAlike_3.0_Unported_License"
            >
              Creative Commons Attribution-ShareAlike License
            </a>
            <a
              rel="license"
              href="//creativecommons.org/licenses/by-sa/3.0/"
            ></a>{" "}
            .
          </p>
          <p>Page generated on {buildDate}</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
